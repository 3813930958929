@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'sidebar';

$header-height: 90px;

html, body, #root {
  height: 100%;
  width: 100%;
}

.admin {
  display: flex;
  height: calc(100% - #{$header-height});
  width: 100%;
}

body {
  font-family: 'Roboto', serif;
  font-size: 14px;
}

.form-control {
  font-size: 14px;
}

.btn {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.legend {
  position: absolute;
  z-index: 10001;
  right: 30px;
  width: 250px;
  top: 320px;
  background: white;
  padding: 10px 15px;
  padding-bottom: 5px;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}
.legend-close {
    position: absolute;
    right: 5px;
    top: 0px;
    cursor: pointer;
    font-size: 14px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.legend-line {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding-top: 10px;
}

.legend-open {
  cursor: pointer;
  font-size: 12px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.legend-name {
  font-size: 12px;
  padding-left: 5px
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.sidebar-tab {
  font-size: 11px;
}

.sidebar-tab-content {
  background-color: white;
  margin-top: 15px;
  padding: 15px;
}

.text-admin-color {
  color: #717274
}

.text-admin-color-focus {
  outline: none !important;
  color: #2e65fd;
  border-left: 3px solid #3d6ffc !important;
  background: rgb(61,111,252);
  background: linear-gradient(90deg, rgba(61,111,252,0.2511379551820728) 0%, rgba(255,255,255,1) 100%);
}

.draw-map-input {
  width: 450px;
}

.draw-map-controls {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(245, 246, 249);
  padding-right: 50px;
  padding-left: 50px;
  justify-content: space-between;
}

.custom-row {
  display: flex;
  flex-direction: row;
  height: 100%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.header {
  background-color: transparent;
  height: $header-height;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table.admin-table {
  table-layout: auto !important;
  overflow: auto;
  display: block;
}
//.admin-table {
//  background-color: #f5f6f9;
//  padding-right: 50px;
//  padding-left: 50px;
//}

.admin-content {
  padding: 30px;
  background-color: #F1F1F1;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.admin-table-search {
  display: flex;
  margin-bottom: 10px;
}

.admin-content-header {
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  border-bottom: 2px solid rgb(194, 194, 194);
  display: flex;
  align-items: center;
  height: 60px;
}

.pagination {
  justify-content: flex-end;
}

.edit-icon {
  padding: 10px;
  cursor: pointer;
}

.edit-icon:hover > svg > path {
  fill: #0b2e13;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #d6d6d6;
  border-radius: 20px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.sidebar-scroll::-webkit-scrollbar {
  display: none;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.no-border {
  border: 0;
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
}
.css-f0yifs-control:hover {
    box-shadow: none !important;
}
.btn:focus {
  box-shadow: none;
}

.text-admin-color:focus {
  outline: none;
  color: #2e65fd;
  border-left: 3px solid #3d6ffc !important;
  background: rgb(61,111,252);
  background: linear-gradient(90deg, rgba(61,111,252,0.2511379551820728) 0%, rgba(255,255,255,1) 100%);
}

.css-f0yifs-control{
  box-shadow: none !important;
}
.checkboxDisableIcon {
  filter: grayscale(1)
}

.search-label {
  width: 100%;
}

.modal-header {
  //border-bottom: 1px solid $secondary;
}
.modal-footer {
  border-top: none;
}
textarea.form-control {
  height: 146px;
}
.btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  z-index: 111;
  top: 10px;
  left: 10px;
  display: none !important;
}

.table-header {
  background-color: #6289fc;
  color: white
}

.table thead th {
  vertical-align: middle;
}

.table-row:hover {
  background: rgb(61,111,252);
  background: linear-gradient(90deg, rgba(61,111,252,0.2511379551820728) 0%, rgba(255,255,255,1) 100%);
}

.table-body {
  background-color: white;
}
.map-draw-btn-l {
  margin-left: 20px;
}

.map-draw-btn-r {
  margin-right: 20px;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-radius: 5px;
}

.hidemap {
  cursor: not-allowed;
}

.log-out-sidebar {
  display: none;
}

@media only screen and (max-width: 768px) {
  .btn-toggle {
    display: flex !important;
  }

  .admin-content {
    padding: 30px 15px;
  }

  table.admin-table {
    margin-bottom: 10px;
  }

  span#adminHeader {
    display: none;
  }

  .pagination {
    justify-content: start;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

/*

@media only screen and (max-device-width: 768px) {
  .main-table-body {
    padding-left: 10px;
  }
  .hospital-row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .draw-map-input { 
    width: 150px;    
  }
  .legend {
    top: 320px;
  }
  .btn-toggle {
    display: flex !important;
  }
  .admin-sidebar {
    margin-top: -90px;
  }
  .edit-btn {
    font-size: 12px;
  }
  .admin-table {
    background-color: #f5f6f9; 
    padding-right: 0px;
    padding-left: 0px;
  }
  .admin-content-header{
    padding-left: 20px;
  }
  .admin-content-header-btn {
    padding-left: 20px;
  }
  .draw-map-controls {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media only screen and (max-device-width: 700px) {
  table.admin-table-internets{
    table-layout: auto !important;
    overflow: auto;
    display: block;
  }
}

@media only screen and (max-device-width: 620px) {
  div.row.react-bootstrap-table-pagination {
    margin-right: 0px;
    margin-left: 0px;
  }
  .legend {
    top: 420px;
  }
  .draw-map-input { 
    width: 90%;    
  }
  .custom-row .admin-content-header-btn {
    flex-direction: column;
  }
  .custom-row .map-draw {
    flex-direction: column;
  }
  .map-draw-btn-l {
    margin-left: 0px;
    width: 200px;
    margin-top: 10px;
  }
  
  .map-draw-btn-r {
    margin-right: 0px;
    width: 200px;
    margin-top: 10px;
  }

  span#adminHeader {
    display: none !important;
  }
  .log-out-sidebar {
    display: block;
  }
}

*/

$barHeight: 61px;

.territory-container {
  width: 100%;
  height: calc(100% - 30px - 60px);
  display: flex;
  justify-content: flex-end;
}

.territory-map {
  width: calc(100% - 300px);
  height: 100%;
}

.territory-orgs {
  width: 300px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.territory-orgs > .header-bar {
  min-height: $barHeight;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E2E2E2;
  background-color: #ffffff;
}

.territory-orgs > .header-bar {
  &> input {
    height: 60px;
    width: calc(100% - 60px);
    border: none;
    padding: 0 20px;
    background-color: #fff;

    &:focus {
      outline: none;
    }
  }

  &> .icon {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
}

.territory-orgs > .org-list {
  height: calc(100% - #{$barHeight});
  overflow: auto;
}

.territory-orgs > .org-list > .org {
  background-color: #ffffff;
  color: #9a9a9a;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    background-color: #F2F5F8;
    color: #9a9a9a;

    &> .name {
      color: #000000;
    }

    &> .territories-count {
      color: #8e8e8e;
    }
  }

  &> .name {
    color: #9a9a9a;
  }

  &> .territories-count {
    font-size: 12px;
    color: #bdbdbd;
  }

  &.active {
    background-color: #e2e9ff;
    color: #9a9a9a;

    &> .name {
      color: #000000;
    }

    &> .territories-count {
      font-size: 12px;
      color: #8e8e8e;
    }
  }
}

.territory-orgs > .header-bar {
  &> .title {
    width: calc(100% - 60px);
    padding: 20px 20px 20px 0;
    background-color: #fff;
    font-weight: bold;
  }

  &> .back-icon {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;

    &:hover > svg > path {
      fill: #8e8e8e;
    }
  }
}

.territory-orgs > .territory-list {
  height: $barHeight; // в коде js имеется вычисление высоты
  overflow: auto;
}

.territory-orgs > .territory-list > .territory {
  background-color: #ffffff;
  color: #9a9a9a;
  padding: 5px 20px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: #F2F5F8;
    color: #000000;
  }

  &.active {
    background-color: #e2e9ff;
    color: #4c4c4c;

    &:hover {
      color: #000000;
    }
  }
}

.territory-orgs > .territory-form {
  height: $barHeight; // в коде js имеется вычисление высоты
  overflow: auto;
  padding: 20px;

  &> .title {
    font-size: 14px;
    font-weight: bold;
  }

  &> .other-text {
    font-size: 11px;
    margin-top: 10px;
  }

  & input {
    margin-top: 20px;
  }

  & button {
    margin-top: 20px;

    &> span {
      margin-left: 10px;
    }
  }

  &>ol {
    padding-left: 12px;
  }

  &> .buttons {
    display: flex;
    justify-content: flex-end;
  }
}

.intmap-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.interactive-map {
  width: 100%;
  height: 100%;
}

.pro-sidebar-layout {
  background-color: #F2F5F8;
  padding: 0 15px 15px 15px;
}

.connection-legend {
  background-color: #ffffff;
  margin-top: 15px;
  padding: 15px 15px;
  border-radius: 4px;

  &> .title {
    color: #04003D;
    margin-bottom: 10px;
  }

  &> .element {
    display: flex;
    justify-content: space-between;
    color: #88898B;
    font-size: 12px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;

    &:last-child {
      margin-bottom: 0;
    }

    &> .checkbox-container {
      display: flex;
      justify-content: start;
      align-items: center;

      &> .checkbox > svg {
        width: 20px;
        height: 20px;
      }
      &> .name {
        //display: flex;
        //justify-content: flex-start;
        margin-left: 10px;
        //max-width: 100px;
        &> .count {
          //background-color: #6d1818;
          margin-left: 10px;
          padding: 0 5px;
          border: 1px solid #88898B;
          border-radius: 5px;
          color: #88898B;
          font-size: 10px;
        }
      }
    }

    &> .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      &> img {
        filter: grayscale(1);
      }
    }

    &:hover {
      &> .checkbox-container {
        &> .checkbox > svg > path {
          fill: #5682ff;
        }
        &> .checkbox > svg > circle {
          fill: #B0B0B0;
        }
        &> .name {
          color: #000000;
        }
      }

      &> .icon > img {
        filter: none;
      }
    }

    &.checked {
      &> .checkbox-container > .name {
        color: #000000;
      }
      &> .icon > img {
        filter: none;
      }
    }

    &.checked-all {
      &> .checkbox-container > .checkbox {
        color: #000000;
      }
      &> .icon > img {
        filter: none;
      }
    }
  }
}

.search-content {
  &> .back-container {
    display: flex;
    justify-content: start;
    align-items: end;
    margin-bottom: 15px;

    &> .back {
      font-size: 14px;
      color: #999999;
      cursor: pointer;

      &> svg > path {
        fill: #999999;
      }

      &> span {
        padding: 0 10px;
      }

      &:hover {
        &> svg > path {
          fill: #777777;
        }

        &> span {
          color: #777777;
        }
      }
    }
  }

  &> .territory-info {
    margin: 0 -15px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E2E2E2;

    &> .title {
      color: #000000;
      font-size: 20px;
      margin-bottom: 5px;
    }
  }

  &> .territory-types {
    &> .tabs {
      display: flex;
      justify-content: start;
      margin-bottom: 20px;

      &> .tab {
        color: #999999;
        font-weight: 600;
        margin-right: 40px;

        &:hover {
          cursor: pointer;
          color: #777777;
        }

        &.active {
          color: #000000;
          border-bottom: 3px solid #39D8D8;
          padding-bottom: 5px;
          cursor: default;
        }
      }
    }

    &> .content {

      &> .description {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;

        &> .icon {
          width: 30px;
          height: 30px;
        }

        &> span {
          color: #000000;
          margin-left: 10px;
        }
      }
    }
  }
}

.admin-container {
  width: 100%;
  height: calc(100% - 30px - 60px);
}