$sidebar-bg-color: #ffffff;
$sidebar-color: #a9a9a9;
$sidebar-width: 320px;
$sidebar-collapsed-width: 80px;
$highlight-color: #000000;
$submenu-bg-color: #ffffff;
$submenu-bg-color-collapsed: #2b2b2b;
$icon-bg-color: #2b2b2b;
$icon-size: 35px;
$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

@import '../../node_modules/react-pro-sidebar/dist/scss/styles';

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 14px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: none;
}

//li.pro-menu-item:hover {
//  background: linear-gradient(90deg, #319992, #2DE3BD);
//}
//
//li.pro-menu-item.active {
//  color: #ffffff;
//  background: linear-gradient(90deg, #319992, #2DE3BD);
//}